import React, { Suspense, useEffect } from 'react'
import { Routes, Route, Navigate, Outlet, useParams } from "react-router-dom";
import { FullScreenLoading } from "../components/Loading";
import Main from '../pages/Main';
import CookiePolicy from '../pages/CookiePolicy';
// import PrivacyPolicyEn from '../pages/PrivacyPolicy';
// import PrivacyPolicyEs from '../pages/PrivacyPolicy/spanish';
// import PrivacyPolicyFr from '../pages/PrivacyPolicy/french';
// import PrivacyPolicyIt from '../pages/PrivacyPolicy/italy';
// import PrivacyPolicyDe from '../pages/PrivacyPolicy/dutch';
// import TermsOfUseEn from '../pages/TermsOfUse';
// import TermsOfUseEs from '../pages/TermsOfUse/spanish';
// import TermsOfUseFr from '../pages/TermsOfUse/french';
// import TermsOfUseIt from '../pages/TermsOfUse/italy';
// import TermsOfUseDe from '../pages/TermsOfUse/dutch';
import { useSelector } from 'react-redux';
import { DUTCH_LANGUAGE, ENGLISH_LANGUAGE, FRENCH_LANGUAGE, ITALIAN_LANGUAGE, SPANISH_LANGUAGE,WEBSITE_URL,WEBSITE_URL2 } from '../constants';


const MainWithRedirect = ({ language }) => {
  const { id } = useParams();

  // Redirect if the id is not 'copd'
  if (id !== 'copd') {
    return <Navigate to={`/copd/${language}/copd`} replace />;
  }

  // Render the Main component if id is 'copd'
  return <Main language={language} />;
};



const BaseRoutes = () => {
  const language = useSelector(state => state.language.lang);



  return (
    <Suspense fallback={<FullScreenLoading />}>
      <div className="h-full mx-auto">
        <Routes>


          {/* <Route path={`/copd/fr`} element={<Main language={FRENCH_LANGUAGE} />} />
          <Route path={`/copd/it`} element={<Main language={ITALIAN_LANGUAGE} />} /> */}
          {/* <Route path={`/copd/en/asthma`} element={<Main language={ENGLISH_LANGUAGE} />} /> */}
          {/* <Route path={`/copd/en/:id`} element={<MainWithRedirect language={ENGLISH_LANGUAGE} />} /> */}
          {window.location.origin === WEBSITE_URL || window.location.origin === WEBSITE_URL2 ? <Route path={`/en`} element={<Main language={ENGLISH_LANGUAGE} />} /> : <Route path={`/copd/en/:id`} element={<MainWithRedirect language={ENGLISH_LANGUAGE} />} />}


          {/* <Route path={`/copd/de/asthma`} element={<Main language={DUTCH_LANGUAGE} />} /> */}
          {/* <Route path={`/copd/de/:id`} element={<Main language={DUTCH_LANGUAGE} />} /> */}

          {/* <Route path={`/copd/de/:id`} element={<MainWithRedirect language={DUTCH_LANGUAGE} />} /> */}






          {/* <Route path={`/copd/en/cookie-policy`} element={<CookiePolicy language={ENGLISH_LANGUAGE} />} /> */}
          <Route path={`${window.location.origin === WEBSITE_URL || window.location.origin === WEBSITE_URL2 ? '/en/cookie-policy' : '/copd/en/cookie-policy'}`} element={<CookiePolicy language={ENGLISH_LANGUAGE} />} />
          
          {/* <Route path={`/copd/fr/cookie-policy`} element={<CookiePolicy language={FRENCH_LANGUAGE} />} />
          <Route path={`/copd/it/cookie-policy`} element={<CookiePolicy language={ITALIAN_LANGUAGE} />} />
          <Route path={`/copd/de/cookie-policy`} element={<CookiePolicy language={DUTCH_LANGUAGE} />} /> */}

          <Route
            path="*"
            element={
              window.location.origin === WEBSITE_URL || window.location.origin === WEBSITE_URL2
                ? <Navigate to="/en" replace />
                : <Navigate to={`/copd/${language.value ? language.value : "en"}/copd`} replace />
            }
          />

        </Routes>
      </div>
    </Suspense>
  )
}

export default BaseRoutes