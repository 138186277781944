import React, { useState } from 'react'
import LangSelect from './LangSelect'
import Screens from "./Screens"
import { MdOutlinePlayCircleFilled } from "react-icons/md";
const Header = ({refOffset}) => {
    const [currentVideo, setCurrentVideo] = useState(0);
    return (
        <header className={``}>
            {/* <LangSelect currentVideo={currentVideo} /> */}
            <div className="relative  px-9 sm:px-20 lg:24 xl:px-32 flex flex-col justify-between">
                <img src="/images/logos/logo-top-mobile-1.png" className='block sm:hidden w-full relative z-10 -mt-1' />
                <img src="/images/logos/logo-top-desktop.png" className='hidden sm:block w-10/12 relative z-10 -mt-1' />

                {/* <Screens refOffset={refOffset} setCurrentVideo={setCurrentVideo} /> */}
                {/* <div className=' mx-auto '>
                
                    <MdOutlinePlayCircleFilled color='gray' size={300}/>
                </div> */}


                {/* <img src="/images/logos/logo-bottom-mobile-1.png" className='-mb-1 block sm:hidden w-full relative z-10 -mt-1' />
                <img src="/images/logos/logo-bottom-desktop.png" className='ml-auto hidden sm:block w-10/12 relative z-10 -mt-1' /> */}
            </div>
        </header>
    )
}

export default Header