import React, { useEffect, useState } from 'react'

import WhatIsCOPD from './COPD/WhatIsCOPD';
import MainRiskFact from './COPD/MainRiskFact';
import HowToLive from './COPD/HowToLive';

import WhatIsAsthama from './Asthama/WhatisAsthama';
import AsthamaMainRiskFact from './Asthama/MainRiskFact';
import AsthamaHowToLive from './Asthama/HowToLive';

import References from './COPD/References';
import About from './About';
import AreYouCareGiver from './AreYouCareGiver';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { set_tab } from '../../../../redux/slices/tabSlice';
import "react-modal-video/css/modal-video.min.css"
import VideoContainer from '../../../../components/VideoContainer';



const English = () => {
    const { id } = useParams()
    const [isOpen, setOpen] = useState(false);
    const initialTab = id === 'asthma' ? 0 : id === 'copd' ? 1 : 1;
    const [mainTab, setMainTab] = useState(initialTab)
    const [tab, setTab] = useState(0)
    const [active, setActive] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    console.log(id)


    useEffect(() => {
        setActive(null)
    }, [tab, mainTab])

    useEffect(() => {
        if (id === 'copd') {
            dispatch(set_tab("copd"));
        } else if (id === 'asthma') {
            dispatch(set_tab("asthma"));
        }
    }, [id, dispatch]);




    return (
        <>
            <div className='px-5 sm:px-12 text-justify -mt-5'>
                <div className='bg-primary py-4 mb-3 sm:mb-4'>
                    <h2 className='text-xl sm:text-3xl lg:text-3xl font-bold text-white text-center'>
                        CHIESI’S OPEN DIALOGUE WITH PATIENTS
                    </h2>
                </div>

                <div className='mb-5'>
                    <span className="text-base sm:text-lg lg:text-xl font-bold  leading-1 sm:leading-tight">
                        The constant dialogue with people affected by respiratory diseases, such as asthma and COPD, is the basis of our approach.
                    </span>
                    <p className="text-base sm:text-lg lg:text-xl  leading-1 sm:leading-tight">
                        The primary objective of the campaign is to raise awareness about topics related to the well-being of people living with respiratory conditions, by sharing useful
                        information for patients and caregivers. <span className='font-bold'>Going beyond the constraints imposed by health conditions, and enjoying a life of limitless opportunities is possible.</span>
                    </p>
                </div>

            </div>
            <div>
                {/* <SelectionMainTab mainTab={mainTab} setMainTab={setMainTab} setActive={setActive} setTab={setTab} /> */}
                {mainTab === 1 ?
                    <>
                        <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                            <div className='px-4 mt-5  md:mt-10 mb-5 text-xl sm:text-2xl lg:text-3xl font-bold text-primary text-center'>
                                Discover Felicity’s journey of LIMITLESS determination in dealing with COPD.</div>
                            <div className="relative flex flex-col justify-betwee aspect-video" >
                                <VideoContainer url={"https://ers-dev.s3.eu-west-3.amazonaws.com/media/FELICITY_PAYNE_EN_V3_1.mp4"}/>
                            </div>


                            <div className='mt-5  md:mt-10 mb-10 sm:mb-8'>
                                <p className=" px-4 text-base sm:text-lg lg:text-xl leading-tight">
                                    Here you can find more information about COPD, along with some useful suggestions on how to improve the daily lives of those suffering from it.
                                </p>
                                <p className='px-4 text-base sm:text-lg lg:text-xl leading-tight'>
                                    <strong>The guiding principle is supporting patients in their daily activities to enhance their quality of life</strong>.
                                </p>
                            </div>
                        </div>
                        <SelectionTab tab={tab} setTab={setTab} setActive={setActive} mainTab={mainTab} />
                        <div className='pt-4 xl:pt-0  px-5 sm:px-12'>

                            <div className='px-5 text-justify sm:px-12 py-6 space-y-2 text-base sm:text-lg lg:text-xl leading-tight  font-light bg-gray-200 '>
                                {tab === 0 ?
                                    <WhatIsCOPD />
                                    : tab === 1 ?
                                        <MainRiskFact />
                                        : tab == 2 ?
                                            <HowToLive active={active} setActive={setActive} />
                                            : ""}

                                {tab != 2 &&
                                    <>
                                        <hr className=' bg-black text-black' style={{ height: '2px' }} />
                                        <References tab={tab} />
                                    </>}

                            </div>

                        </div></>
                    :
                    <>
                        <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                            <div className='px-4 mt-5  md:mt-10 mb-5 text-xl sm:text-2xl lg:text-3xl font-bold text-primary text-center'>
                                Discover Giovanni’s journey of LIMITLESS determination in dealing with asthma.</div>
                            <div className="relative flex flex-col justify-betwee aspect-video" >


                                <VideoContainer url={"https://ers-dev.s3.eu-west-3.amazonaws.com/media/GIOVANNI_QUAGLIA_EN_V5_1.mp4"}/>
                            </div>


                            <div className='mt-5  md:mt-10 mb-10 sm:mb-8'>
                                <p className=" px-4 text-base sm:text-lg lg:text-xl leading-tight">
                                    Here you can find more information about asthma, along with some useful suggestions on how to improve the daily lives of those suffering from it.
                                </p>
                                <p className='px-4 text-base sm:text-lg lg:text-xl leading-tight'>
                                    <strong>The guiding principle is supporting patients in their daily activities to enhance their quality of life</strong>.
                                </p>
                            </div>
                        </div>

                        <SelectionTab tab={tab} setTab={setTab} setActive={setActive} /><div className='pt-4 xl:pt-0  px-5 sm:px-12'>

                            <div className='px-5 text-justify sm:px-12 py-6 space-y-2 text-base sm:text-lg lg:text-xl leading-tight  font-light bg-gray-200 '>
                                {tab === 0 ?
                                    <WhatIsAsthama />
                                    : tab === 1 ?
                                        <AsthamaMainRiskFact />
                                        : tab == 2 ?
                                            <AsthamaHowToLive active={active} setActive={setActive} />
                                            : ""}

                            </div>

                        </div></>}
                <div className='pt-4 xl:pt-0  px-5 sm:px-12'>
                    <AreYouCareGiver mainTab={mainTab == 1 ? "COPD" : "ASTHAMA"} />
                </div>
            </div>

            <About />

        </>
    )
}

export default English





export const SelectionTab = ({ tab, setTab, setActive, mainTab }) => {

    return (
        <>
            <div className='px-5 sm:px-12  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 '>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${tab === 0 ? "bg-gray-200  " : "border-2"} border-2   p-4 uppercase text-primary font-medium`} onClick={() => setTab(0)}>
                        {mainTab == 1 ? "What is COPD" : "What is ASTHMA"}
                    </div>
                </div>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${tab === 1 ? "bg-gray-200" : "border-2"}  border-2 p-4 uppercase text-primary font-medium `} onClick={() => setTab(1)}>
                        THE MAIN RISK FACTORS
                    </div>
                </div>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${tab === 2 ? "bg-gray-200" : "border-2"}  border-2 p-4 uppercase text-primary font-medium`} onClick={() => setTab(2)}>
                        {/* HOW TO LIVE WITH COPD */}

                        {mainTab == 1 ? "LIVING WITH COPD" : "LIVING WITH ASTHMA"}
                    </div>

                </div>
            </div>
            <div className={`  hidden xl:block    px-5 sm:px-12  xl:grid xl:grid-cols-3 gap-3`}>
                <div className={`${tab === 0 ? " h-4 bg-gray-200 " : ""}`} ></div>
                <div className={`${tab === 1 ? "h-4 bg-gray-200" : ""}`} ></div>
                <div className={`${tab === 2 ? "h-4 bg-gray-200" : ""}`} ></div>
            </div>

        </>
    )
}


export const SelectionMainTab = ({ mainTab, setMainTab, setMainActive, setTab }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <>
            <div className='px-5 sm:px-12  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 gap-2 mb-4'>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${mainTab === 0 ? "bg-primary text-white  " : "border-2"} border-2  border-primary p-4 uppercase text-primary font-medium`} onClick={() => {
                        setMainTab(0)
                        setTab(0)
                        dispatch(set_tab("asthma"))
                        navigate('/copd/en/asthma');
                    }}>
                        ASTHMA
                    </div>
                </div>
                <div className=''>
                    <div className={`text-lg flex-shrink-0 text-center sm:text-xl lg:text-2xl w-auto cursor-pointer ${mainTab === 1 ? "bg-primary text-white" : "border-2"}  border-2 border-primary p-4 uppercase text-primary font-medium `} onClick={() => {
                        setMainTab(1)
                        setTab(0)
                        dispatch(set_tab("copd"))
                        navigate('/copd/en/copd');
                    }}>
                        COPD
                    </div>
                </div>
            </div>

        </>
    )
}